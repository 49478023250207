import { auth } from "./firebase";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";

export const doSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    'hd': 'flip.shop'
  });
  const result = await signInWithPopup(auth, provider);
  return result;
  //   return signInWithPopup(auth, email, password);
  // const user = result.user;

  // add user to firestore
};
export const doSignInWithEmailAndPassword = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const doSignOut = () => {
  return auth.signOut();
};

