import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState} from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";


const Dashboard = ({getToken}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [updatedTimeData, setUpdatedTimeData] = useState([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_TRACK_DATABASE_UPDATE_TIMES_URL, {
            headers: {
                "flipinator-token":`${getToken}`,
            },
        }).then((response) => response.json()).then((json) => setUpdatedTimeData(json))
    }, [getToken]);

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="DASHBOARD" subtitle="Welcome to Flipinator" />
            </Box>

            {/* GRID & CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
            >
                {/* ROW 2 */}

                <Box
                    gridColumn="span 6"
                    gridRow="span 3"
                    backgroundColor={colors.primary[400]}
                    overflow="auto"
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom={`4px solid ${colors.primary[500]}`}
                        colors={colors.grey[100]}
                        p="15px"
                    >
                        <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                            Last Time Database Was Updated
                        </Typography>
                    </Box>
                    { updatedTimeData.map((item, i)=> (
                        <Box
                            key={`${item.id}-${i}`}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p="15px"
                        >
                            <Box>
                                <Typography color={colors.grey[100]}>
                                    {item.id}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography color={colors.grey[100]}>
                                    {item.database}
                                </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>{item.update_time}</Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;
