import React from "react";
import { Routes, Route } from "react-router-dom";

import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/Dashboard";
import CanalOrders from "./scenes/shopify-orders/canal";
import CarroOrders from "./scenes/shopify-orders/carro";
import Carro2Orders from "./scenes/shopify-orders/carro2";
import CortinaOrders from "./scenes/shopify-orders/cortina";
import FashionGoOrders from "./scenes/shopify-orders/fashiongo";
import TrendsiOrders from "./scenes/shopify-orders/trendsi";
import CanalFlipOrdersSent from "./scenes/looker/canal-flip-orders-sent";
import CarroFlipOrdersSent from "./scenes/looker/carro-flip-orders-sent";
import Carro2FlipOrdersSent from "./scenes/looker/carro2-flip-orders-sent";
import ConvictionalLookerReport from "./scenes/convictional-looker-report";
import HubspotMondayReport from "./scenes/hubspot_report/monday_report";
import HubspotFridayReportVendorIdsUpdatedByBrandName from "./scenes/hubspot_report/friday_report_vendor_id_updated_by_brand_name";
import HubspotFridayReportBrandDuplicatesForReview from "./scenes/hubspot_report/friday_report_brand_duplicates_for_review";
import HubspotFridayReportMissingDropshipBrands from "./scenes/hubspot_report/friday_report_missing_dropship_brands";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

import {useAuth} from "./contexts/authContext";
import Login from "./components/auth/login";

import useToken from "./scenes/App/useToken";
import MySidebar from "./scenes/global/MySidebar";

function App() {
  const { userLoggedIn } = useAuth()
  const { token, setToken } = useToken();

  const [theme, colorMode] = useMode();


  if(!userLoggedIn && !token){
      return (
        <Routes>
          <Route path="*" element={<Login setToken={setToken}/>}/>
          <Route path="/login" element={<Login setToken={setToken}/>}/>
        </Routes>
      );
  }else{
      return (
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app">
              <MySidebar />
              <main className="content">
                <Topbar setToken={setToken}/>
                <Routes>
                  <Route path="/" element={<Dashboard getToken={token}/>}/>
                  <Route path="/canal" element={<CanalOrders getToken={token}/>}/>
                  <Route path="/carro" element={<CarroOrders getToken={token}/>}/>
                  <Route path="/carro2" element={<Carro2Orders getToken={token}/>}/>
                  <Route path="/cortina" element={<CortinaOrders getToken={token}/>}/>
                  <Route path="/fashiongo" element={<FashionGoOrders getToken={token}/>}/>
                  <Route path="/trendsi" element={<TrendsiOrders getToken={token}/>}/>

                  {/* Flip Orders Sent */}
                  <Route path="/canal-flip-orders-sent" element={<CanalFlipOrdersSent getToken={token}/>}/>
                  <Route path="/carro-flip-orders-sent" element={<CarroFlipOrdersSent getToken={token}/>}/>
                  <Route path="/carro2-flip-orders-sent" element={<Carro2FlipOrdersSent getToken={token}/>}/>

                  {/* Convictional Looker Report */}
                  <Route path="/get-convictional-looker-report" element={<ConvictionalLookerReport getToken={token}/>}/>

                  {/*Hubspot Reports*/}
                  <Route path="/hubspot-brands-updated-by-vendor-id" element={<HubspotMondayReport getToken={token}/>}/>
                  <Route path="/brands-vendor-id-updated-by-brand-name" element={<HubspotFridayReportVendorIdsUpdatedByBrandName getToken={token}/>}/>
                  <Route path="/duplicate-hubspot-brands-for-review" element={<HubspotFridayReportBrandDuplicatesForReview getToken={token}/>}/>
                  <Route path="/missing-dropship-brands" element={<HubspotFridayReportMissingDropshipBrands getToken={token}/>}/>

                </Routes>
              </main>
            </div>
          </ThemeProvider>
        </ColorModeContext.Provider>
      );
  }
}

// }

export default App;
