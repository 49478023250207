import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
// import { mockDataContacts } from "../../../data/mockData";
import Header from "../../../components/Header";
import { useTheme } from "@mui/material";
import React, { useEffect, useState} from "react";

// const Orders = ({ onError }) => {
const HubspotFridayReportMissingDropshipBrands = ({getToken}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState([]);
  const columns = [
    // {field: "id", headerName: "ID" },
    {field: "date", headerName: "Date", width: 100 },
    {field: "looker_brand_name", headerName: "Looker Brand Name", width: 150 },
    {field: "looker_flip_vendor_id", headerName: "Looker Flip Vendor ID", width: 150 },
    {field: "looker_flip_margin", headerName: "Looker Flip Margin", width: 150 },
    {field: "looker_flip_brand_status", headerName: "Looker Flip Brand Status", width: 200 }

  ];
  useEffect(() => {
      fetch(process.env.REACT_APP_HUBSPOT_FRIDAY_REPORT_MISSING_DROPSHIP_BRANDS_URL, {
          headers: {
              "flipinator-token":`${getToken}`,
        },
      })
          .then((response) => response.json())
          .then((json) => setUsers(json));
      }
  , [getToken]);


  return (
    <Box m="20px">
      <Header
        title="HUBSPOT FRIDAY REPORT - MISSING DROPSHIP BRANDS"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={users}
          columns={columns}
          loading={!users.length}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: false },
              printOptions: { disableToolbarButton: true },
              // showQuickFilter: true,
              // quickFilterProps: { debounceMs: 250 },
            },
          }}
          initialState={{
            sorting: {
                sortModel: [{ field: 'id', sort: 'asc' }],
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default HubspotFridayReportMissingDropshipBrands;
