import { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses, MenuItemStyles } from 'react-pro-sidebar';
import { Box, Typography,IconButton, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import TableChartIcon from '@mui/icons-material/TableChart';
import ViewListIcon from '@mui/icons-material/ViewList';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import WifiIcon from '@mui/icons-material/Wifi';
import {tokens} from "../../theme";

const hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const Item = ({ title, to, selected, setSelected, icon }) => {
    return (
        <MenuItem
            active={selected === title}
            component={<Link to={to} />}
            onClick={() => setSelected(title)}
            icon={icon}
        >
        {title}
        </MenuItem>
    );
};

const MySidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");

  const menuItemStyles: MenuItemStyles = {
        subMenuContent: ({ level }) => ({
          backgroundColor:
            level === 0 ? hexToRgba('#384c74', 1) : 'transparent',
        }),
        button: {
            [`&.${menuClasses.disabled}`]: {
                color: '#ffffff',
            },
            [`&.${menuClasses.active}`]: {
                color: '#d359ff',
            },
            '&:hover': {
                backgroundColor: "transparent",
                color: '#868dfb',
            },
        },

    };
  return (
      <Sidebar
          collapsed={isCollapsed}
          backgroundColor={hexToRgba(colors.primary[400], 1)}
          style={{
          }}
      >
          <Menu iconShape="square" menuItemStyles={menuItemStyles}>
              <MenuItem
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                  style={{
                      margin: "10px 0 20px 0",
                      color: colors.grey[100],
                  }}
              >
                  {!isCollapsed && (
                      <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          ml="15px"
                      >
                          <Typography variant="h3" color={colors.grey[100]}>
                            FLIPINATOR
                          </Typography>
                          <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                              <MenuOutlinedIcon />
                          </IconButton>
                      </Box>
                  )}
              </MenuItem>
              <Item
                  title="Dashboard"
                  to="/"
                  icon={<HomeIcon />}
              />
              <SubMenu defaultOpen label="Orders" icon={<ViewListIcon />} >
                  <Item
                      title="Canal Orders"
                      to="/canal"
                      selected={selected}
                      setSelected={setSelected}
                  />

                  <Item
                      title="Carro Orders"
                      to="/carro"
                      selected={selected}
                      setSelected={setSelected}
                  />

                  <Item
                      title="Carro 2 Orders"
                      to="/carro2"
                      selected={selected}
                      setSelected={setSelected}
                  />
                  <Item
                      title="Cortina Orders"
                      to="/cortina"
                      selected={selected}
                      setSelected={setSelected}
                  />
                  <Item
                      title="Fashion Go Orders"
                      to="/fashiongo"
                      selected={selected}
                      setSelected={setSelected}
                  />
                  <Item
                      title="Trendsi Orders"
                      to="/trendsi"
                      selected={selected}
                      setSelected={setSelected}
                  />
          </SubMenu>
              <SubMenu label="Flip Orders" icon={<TableChartIcon />} >
                  <Item
                      title="Canal Flip Orders Sent"
                      to="/canal-flip-orders-sent"
                      selected={selected}
                      setSelected={setSelected}
                  />
                  <Item
                      title="Carro Flip Orders Sent"
                      to="/carro-flip-orders-sent"
                      selected={selected}
                      setSelected={setSelected}
                  />
                  <Item
                      title="Carro 2 Flip Orders Sent"
                      to="/carro2-flip-orders-sent"
                      selected={selected}
                      setSelected={setSelected}
                  />
              </SubMenu>
              <SubMenu label="Hubspot Reports" icon={<TableChartIcon />} >
                <SubMenu defaultOpen label="Monday Reports" icon={<TableChartIcon />} >
                  <Item
                      title="Hubspot Brands Updated By Vendor Id"
                      to="/hubspot-brands-updated-by-vendor-id"
                      selected={selected}
                      setSelected={setSelected}
                  />
                </SubMenu>
                <SubMenu defaultOpen label="Friday Reports" icon={<TableChartIcon />} >
                  <Item
                      title="Brands Vendor Id Updated By Brand Name"
                      to="/brands-vendor-id-updated-by-brand-name"
                      selected={selected}
                      setSelected={setSelected}
                  />
                  <Item
                      title="Duplicate Hubspot Brands For Review"
                      to="/duplicate-hubspot-brands-for-review"
                      selected={selected}
                      setSelected={setSelected}
                  />
                  <Item
                      title="Missing Dropship Brands"
                      to="/missing-dropship-brands"
                      selected={selected}
                      setSelected={setSelected}
                  />
                </SubMenu>

              </SubMenu>
              <Item
                  title="Convictional Looker Report"
                  to="/get-convictional-looker-report"
                  icon={<ViewListIcon />}
              />
              <Item
                  title="Status"
                  to="/status"
                  icon={<WifiIcon />}
              />
          </Menu>
      </Sidebar>
  );
};

export default MySidebar;