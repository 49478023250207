import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockDataContacts } from "../../../data/mockData";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import React, { useEffect, useState} from "react";

// const Orders = ({ onError }) => {
const ConvictionalLookerReport = ({getToken}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState([]);
  const columns = [
    {field: "date", headerName: "Report Date", width: 150,},
    {field: "convictional_order_id", headerName: "Convictional Order ID", width: 150,
        renderCell: (params) => <a href={"https://app.convictional.com/orders/"+params.row.convictional_order_id} target="_blank" rel="noopener noreferrer">{params.row.convictional_order_id}</a>,
        valueGetter: (params) => {return "https://app.convictional.com/orders/"+params.row.convictional_order_id},
    },
    {field: "seller_order_id", headerName: "Seller Order Number", width: 150,},
    {field: "flip_order_id", headerName: "Flip Order Number", width: 150,},
    {field: "is_it_flagged", headerName: "Is It Flagged", width: 150,},
    {field: "does_it_exist_in_convictional", headerName: "Does it exist in convictional", width: 150,},
    {field: "looker_integration_type", headerName: "Looker Integration Type", width: 150,},
    {field: "created_at_date", headerName: "Created At Date", width: 150,}
  ];

  useEffect(() => {
        fetch(process.env.REACT_APP_CONVICTIONAL_LOOKER_REPORT_URL, {
          headers: {
              "flipinator-token":`${getToken}`,
        },
      })
            .then((response) => response.json())
            .then((json) => setUsers(json));
  }, [getToken]);
  
  return (
    <Box m="20px">
      <Header
        title="CONVICTIONAL LOOKER REPORT"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={users}
          columns={columns}
          loading={!users.length}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: false },
              printOptions: { disableToolbarButton: true },
              // showQuickFilter: true,
              // quickFilterProps: { debounceMs: 250 },
            },
          }}
          initialState={{
            sorting: {
                sortModel: [{ field: 'flip_order_id', sort: 'desc' }],
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ConvictionalLookerReport;
