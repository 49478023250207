import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('flipinator_token');
        const userToken = JSON.parse(tokenString);
        return userToken?.flipinator_token
    };

    const [token, setToken] = useState(getToken());
    const saveToken = userToken => {
        localStorage.setItem('flipinator_token', JSON.stringify(userToken));
        setToken(userToken.flipinator_token);
    };

    return {
        setToken: saveToken,
        token
    }
}