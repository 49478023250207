import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
// import { mockDataContacts } from "../../../data/mockData";
import Header from "../../../components/Header";
import { useTheme } from "@mui/material";
import React, { useEffect, useState} from "react";

// const Orders = ({ onError }) => {
const CarroOrders = ({getToken}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [users, setUsers] = useState([]);

    const columns = [
    {field: "order_number", headerName: "Order Number", width: 100 },
    {
        field: "shopify_link",
        headerName: "Shopify Link",
        width: 100,
        renderCell: (params) => <a href={"https://admin.shopify.com/store/humans-inc-0101/orders/"+params.row.shopify_id} target="_blank" rel="noopener noreferrer">Link</a>,
        valueGetter: (params) => {return "https://admin.shopify.com/store/humans-inc-0101/orders/"+params.row.shopify_id},
    },
    {field: "shopify_id", headerName: "Shopify ID", width: 150,},
    {field: "order_date", headerName: "Order Date", width: 150 },
    {field: "fulfillment_status", headerName: "Fulfillment Status", width: 150 },
    {field: "financial_status", headerName: "Financial Status", width: 150 },
    {field: "note_attributes", headerName: "Note Attributes", width: 150 },
    {field: "tags", headerName: "Tags", width: 150 },
    {field: "shipping_carrier", headerName: "Shipping Carrier", width: 150 },
    {field: "shipment_tracking_number", headerName: "Shipment Tracking Number", width: 200 }

    ];

    useEffect(() => {
        fetch(process.env.REACT_APP_CARRO_DATAGRID_URL, {
          headers: {
              "flipinator-token":`${getToken}`,
        },
      })
            .then((response) => response.json())
            .then((json) => setUsers(json));
    }, [getToken]);

    return (
        <Box m="20px">
            <Header
                title="CARRO ORDERS"
            />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                  rows={users}
                  columns={columns}
                  loading={!users.length}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: false },
                      printOptions: { disableToolbarButton: true },
                      // showQuickFilter: true,
                      // quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  initialState={{
                    sorting: {
                        sortModel: [{ field: 'shopify_id', sort: 'desc' }],
                    },
                  }}
                />
            </Box>
        </Box>
    );
};

export default CarroOrders;
